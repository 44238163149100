import "../styles/mui.scss";

declare global {
  interface Window { setComplete: any; }
}

import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import { CharacterCreatorIframe } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';

import Footer from '../components/Footer';
import "react-image-gallery/styles/scss/image-gallery.scss";

import { useState } from 'react';
import { SiteHelmet } from "../components/Helmet";

import FixedBar from "../components/FixedBar";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { colors } from "../styles/colors";

const WrapperCss = css`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const CountdownCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'LondrinaSolid';
  font-size: 1.8em;
  text-align: center;
  color: ${colors.logoOrange};
`

const PageTemplate = css`
  .site-main {
    width: 100%;
  }
`;


type Props = {
  data: any
}

// Renderer callback with condition
const CountdownRenderer: React.FC<CountdownRenderProps> = ({ days, hours, formatted }) => {
  return <div css={CountdownCSS}>
    <span style={{ marginBottom: '.5em' }} >1.2 Giveaway</span>
    <span style={{ fontSize: '1.2em' }} >{(days > 0) && <span>{days} {days > 1 ? ' days - ' : 'day - '}</span>}{(hours > 0 ? `${formatted.hours}:` : '')}{formatted.minutes}:{formatted.seconds}</span>
  </div>;
};


// Landing
const Landing: React.FC<Props> = () => {

  const GIVEAWAY_START_TIME = 1722351600000

  let iframeRef: HTMLIFrameElement;

  const [countdownComplete, setCountdownComplete] = useState(false);

  const resizeIframe = () => {
    if (iframeRef) {
      iframeRef.style.height = '1034px'
      iframeRef.style.height =
        iframeRef.contentWindow!.document.body.scrollHeight + 'px';
    }
  }

  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    window.onresize = resizeIframe
    window.setComplete = () => setCountdownComplete(true)
  }

  return <IndexLayout>
    <SiteHelmet />
    <Wrapper css={[PageTemplate, WrapperCss]}>
      <FixedBar includeHome />
      {
        (countdownComplete || (Date.now() >= GIVEAWAY_START_TIME))
          ? <iframe onLoad={resizeIframe} ref={(f) => iframeRef = f!} width={'100%'} css={[CharacterCreatorIframe]} src="../iframes/character-creator/index.html"></iframe>
          : <div style={{ height: '94vh', display: 'flex', alignItems: 'center' }}>
            <Countdown date={GIVEAWAY_START_TIME} renderer={CountdownRenderer} onComplete={() => setCountdownComplete(true)} />
          </div>
      }
      <Footer />
    </Wrapper>
  </IndexLayout >
}

export default Landing;
